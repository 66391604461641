// extracted by mini-css-extract-plugin
export var lgSpacing = "Orbit-module--lg--spacing--01e3b";
export var mdSpacing = "Orbit-module--md--spacing--07e63";
export var noneSpacing = "Orbit-module--none--spacing--cb17d";
export var orbit = "Orbit-module--orbit--f6132";
export var rightBounce = "Orbit-module--right-bounce--e93d1";
export var smSpacing = "Orbit-module--sm--spacing--d7b51";
export var uniformButtons = "Orbit-module--uniformButtons--bc4c7";
export var uniformHeadings = "Orbit-module--uniformHeadings--76463";
export var xlSpacing = "Orbit-module--xl--spacing--aeee1";
export var xsSpacing = "Orbit-module--xs--spacing--ac6fe";